<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="记录收付款"
      @ok="handleSubmit"
    >
      <a-form
        class="simiot-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >

        <a-form-item label="客户名称">
          <span>{{ agent.name }}</span>
        </a-form-item>

        <a-form-item label="收付款类型">
          <a-select
            v-decorator="['transfer_type', {
              initialValue: 'offline_deposit',
              rules: [{ required: true, message: '请选择收付款类型' }]
            }]"
          >
            <a-select-option
              v-for="option in transferTypeOptions"
              :key="option.value"
              :value="option.value"
            >
              {{ option.label }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="支付方式">
          <a-select
            @focus="fetchPayWayOptions"
            :loading="loadingPayWayOptions"
            v-decorator="['pay_way', {
              rules: [{ required: true, message: '请选择支付方式' }]
            }]"
          >
            <a-select-option
              v-for="option in payWayOptions"
              :key="option.slug"
              :value="option.slug"
            >
              {{ option.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="金额(元)">
          <a-input-number
            style="width: 100%"
            :min="0.01"
            :max="10000000"
            :step="0.01"
            :precision="2"
            v-decorator="['amount', {
              rules: [
                { required: true, message: '请输入金额' },
              ]
            }]"
          />
          <span>*可以输入小数点后两位</span>
        </a-form-item>

        <a-form-item label="收付款日期">
          <a-date-picker
            style="width: 100%;"
            :disabled-date="disabledPaidAt"
            v-decorator="['paid_at', {
              initialValue: this.$moment(),
              rules: [
                { required: true, message: '请选择收付款日期' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="支付凭据截图" required>
          <span
            slot="extra"
            class="tip-text"
          >支持.jpg .jpeg .bmp .png格式照片，大小不超过10M</span>
          <upload-image
            :is-add-watermark="isAddWatermark"
            accept=".jpg,.jpeg,.bmp,.png"
            @change="handlePayOrderChange"
            v-decorator="['pay_order', {rules: [
              { validator: checkPayOrder }
            ]
            }]"
          />
        </a-form-item>

        <a-form-item :label="`备注`">
          <a-textarea
            v-decorator="['remark', {
              rules: [
                { max: 200, message: '最多200个字符' },
                { required: true, message: '请输入备注' }
              ]
            }]"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { createAgentTransfer, findTransferPayWayOptions } from '@/api/transfer'
import UploadImage from '@/components/Upload/Image'
import { findAgent } from '@/api/agent'
import { formatDate } from '@/utils/time'

export default {
  name: 'NewAgentTransfer',
  components: { UploadImage },
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      transferTypeOptions: [
        { label: '线下收款', value: 'offline_deposit' },
        { label: '扣款', value: 'withdrawal' }
      ],
      payWayOptions: [],
      loadingPayWayOptions: false,
      payOrder: undefined,
      agent: {},
      submitting: false,
      form: this.$form.createForm(this, { name: 'transfer' }),
      isAddWatermark: true // 是否添加水印
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },

    agentId() {
      return parseInt(this.$route.params.id)
    }
  },
  created() {
    this.fetchFormData()
  },
  methods: {
    // 检查是否上传支付凭据
    checkPayOrder(rule, value, callback) {
      if (!this.payOrder || this.payOrder[0].status === 'error') {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请上传支付凭据')
        return
      }

      callback()
    },
    disabledPaidAt(current) {
      return current && current > this.$moment().endOf('day')
    },

    fetchFormData() {
      findAgent(this.agentId).then((res) => {
        this.agent = res.data
      })
    },

    fetchPayWayOptions() {
      if (this.payWayOptions.length === 0) {
        this.loadingPayWayOptions = true
        findTransferPayWayOptions().then(res => {
          if (res.code === 0) {
            this.payWayOptions = res.data
          }
          this.loadingPayWayOptions = false
        })
      }
    },

    handlePayOrderChange(fileList) {
      if (Object.keys(fileList).length > 0) {
        this.payOrder = fileList
      } else {
        this.payOrder = undefined
      }
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      if (this.$lodash.find(this.payOrder, { status: 'uploading' })) {
        this.$warning({
          title: '文件尚未上传完成',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            agent_id: this.agentId,
            paid_at: formatDate(values.paid_at)
          }

          if (this.payOrder === undefined) {
            delete data.pay_order
          } else {
            data.pay_order = this.payOrder[0].response
          }

          createAgentTransfer(data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
